<template>
  <div>
    <v-container>
        <h1>Registro</h1>
      <form>
        <v-text-field
          v-model="nameUser"
          :error-messages="nameUserErrors"
          label="Nombre de usuario"
          required
          @input="$v.nameUser.$touch()"
          @blur="$v.nameUser.$touch()"
        ></v-text-field>
        <v-text-field
          v-model="nameEcommerce"
          :error-messages="nameEcommerceErrors"
          label="Nombre de empresa"
          required
          @input="$v.nameEcommerce.$touch()"
          @blur="$v.nameEcommerce.$touch()"
        ></v-text-field>
        <v-text-field
          v-model="email"
          :error-messages="emailErrors"
          label="E-mail"
          required
          @input="$v.email.$touch()"
          @blur="$v.email.$touch()"
        ></v-text-field>
        <v-text-field
          v-model="password"
          :error-messages="passwordErrors"
          :counter="10"
          label="Contraseña"
          required
          @input="$v.password.$touch()"
          @blur="$v.password.$touch()"
        ></v-text-field>
        <v-text-field
          v-model="confirmPassword"
          :error-messages="confirmPasswordErrors"
          :counter="10"
          label="Contraseña"
          required
          @input="$v.confirmPassword.$touch()"
          @blur="$v.confirmPassword.$touch()"
        ></v-text-field>

        <v-btn class="mr-4" @click="submit"> submit </v-btn>
        <v-btn @click="clear"> Limpiar </v-btn>
      </form>
    </v-container>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";

export default {
  name: "Register",
  mixins: [validationMixin],
  validations: {
    password: { required, maxLength: maxLength(10) },
    confirmPassword: { required, maxLength: maxLength(10) },
    nameUser: { required },
    nameEcommerce: { required },
    email: { required, email },
  },

  data: () => ({
    password: "",
    confirmPassword: "",
    nameUser: "",
    nameEcommerce: "",
    email: "",
  }),

  computed: {
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.maxLength &&
        errors.push("password must be at most 10 characters long");
      !this.$v.password.required && errors.push("password is required.");
      if (this.$v.password.$model != this.$v.confirmPassword.$model) {
        errors.push("Las contraseñas no coinciden");
      }
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Debe ser un correo válido");
      !this.$v.email.required && errors.push("Tu correo es requerido");
      return errors;
    },
    nameEcommerceErrors() {
      const errors = [];
      if (!this.$v.nameEcommerce.$dirty) return errors;
      !this.$v.nameEcommerce.required && errors.push("Nombre de la empresa es requerido");
      return errors;
    },
    nameUserErrors() {
      const errors = [];
      if (!this.$v.nameEcommerce.$dirty) return errors;
      !this.$v.nameUser.required && errors.push("Su nombre es requerido");
      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.confirmPassword.$dirty) return errors;
      !this.$v.confirmPassword.maxLength && errors.push("La contraseña debe tener 10 caracteres");
      !this.$v.confirmPassword.required && errors.push("Confirmacion de contraseña es requerida");
      if (this.$v.password.$model != this.$v.confirmPassword.$model) {
        errors.push("Las contraseñas no coinciden");
      }
      !this.$v.password.required && errors.push("password is required.");

      return errors;
    },
  },

  methods: {
    submit() {
      this.$v.$touch();
    },
    clear() {
      this.$v.$reset();
      this.password = "";
      this.nameEcommerce = "";
      this.nameUser = "";
      this.email = "";
      this.confirmPassword = "";
    },
  },
};
</script>